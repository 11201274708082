@import 'styles/theme.scss';

.title {
  color: $primary-dark;
  margin: 30px 0 !important;
}
.itemContainer {
  a {
    line-height: 24px;
    font-size: 16px;
    color: $secondary-main;
    text-decoration: none;
    font-weight: $font-weight-400;
    * {
      vertical-align: middle;
    }
    svg {
      font-size: 20px;
      margin-left: 6px;
    }
  }
  :global {
    .MuiButton-containedSecondary {
      font-weight: $font-weight-500 !important;
    }
  }
}

.multiItems {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.Left {
  * {
    text-align: left;
  }
}
.Center {
  * {
    text-align: center;
  }
}
.One_Column {
  width: 66%;
  margin: 0 auto;
  @media (max-width: $md) {
    width: 100%;
  }
}
.blurb {
  p {
    color: $font-color !important;
    margin-bottom: 0 !important;
  }
  padding: 8px 0;
}

.linkText {
  color: $white;
  padding: 2px 32px;
}

.itemTitle {
  color: $primary-dark;
}

.bg {
  border-radius: 6px;
  background-color: $primary-50;
  padding: 56px 76px;
  @media (max-width: $md) {
    padding: 24px 16px;
  }
}
